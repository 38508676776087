@import '~styles/variables';
@import '~styles/mixins';
@import '~styles/components';

.not-found {
  @extend %background;
  width: 100%;
  min-height: calc(100vh - #{$header-height});
  @include rem-calc(padding, 16, 20, 48);
  box-sizing: border-box;

  &__container {
    position: relative;
    @include rem-calc(width, 184);
    @include rem-calc(height, 184);
    background: url(~assets/img/background-nise.png),
      linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 100%), #ed9eb0;
    background-blend-mode: screen, normal, normal;
    box-shadow: 0px 24px 46px #db899b, inset 0px 0px 22px #e399aa;
    border-radius: 35px;
    @include rem-calc(margin, 16, auto, 32);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    @include rem-calc(font-size, 60);
    font-weight: bold;
    color: $white;
  }

  &__subtitle {
    @include rem-calc(font-size, 36);
    @include rem-calc(line-height, 44);
    font-weight: bold;
    text-align: center;
    color: $white;
    @include rem-calc(margin, 0, auto, 20);
    @include rem-calc(max-width, 314);
  }

  &__info {
    @include rem-calc(font-size, 19);
    @include rem-calc(line-height, 27);
    text-align: center;
    color: $white;
    @include rem-calc(margin, 0, auto, 36);
    @include rem-calc(max-width, 314);
  }

  &__take-me-home {
    margin: 0 auto;
  }

  @include smaller($mobileLarge) {
    &__take-me-home {
      max-width: unset;
    }
  }

  @include larger($laptopMiddle) {
    &__container {
      @include rem-calc(width, 328);
      @include rem-calc(height, 328);
      @include rem-calc(margin, 80, auto, 60);
    }

    &__title {
      @include rem-calc(font-size, 100);
    }

    &__subtitle {
      @include rem-calc(font-size, 52);
      @include rem-calc(line-height, 64);
      @include rem-calc(margin, 0 auto, 32);
      @include rem-calc(max-width, 498);
    }

    &__info {
      @include rem-calc(font-size, 19);
      @include rem-calc(line-height, 27);
      @include rem-calc(margin-bottom, 54);
    }

    &__take-me-home {
      @include rem-calc(margin, 0, auto, 60);
    }
  }
}

@import 'variables';

@mixin smaller($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin larger($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin between($breakpointMin, $breakpointMax) {
  @media (min-width: #{$breakpointMin}) and (max-width: #{$breakpointMax}) {
    @content;
  }
}

// $property: property name
// $values...: values in px
// e.g. @include rem-calc(margin, 16, auto, 32) => margin: 1rem auto 2rem
@mixin rem-calc($property, $values...) {
  $baseSize: 16;
  $max: length($values);
  $remValues: '';

  @for $i from 1 through $max {
    @if type-of(nth($values, $i)) == number {
      $value: nth($values, $i) / $baseSize;
      $remValues: #{$remValues + $value}rem;
    } @else {
      $remValues: #{$remValues + nth($values, $i)};
    }
    @if $i < $max {
      $remValues: #{$remValues + ' '};
    }
  }

  #{$property}: $remValues;
}

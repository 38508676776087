@import 'variables';
@import 'mixins';
@import 'components';

@font-face {
  font-family: 'GT Pressura';
  src: url('~assets/fonts/gt-pressura/gt-pressura-regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'GT Pressura';
  src: url('~assets/fonts/gt-pressura/gt-pressura-bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'GT Pressura';
  src: url('~assets/fonts/gt-pressura/gt-pressura-light.ttf') format('truetype');
  font-weight: 300;
}

html,
body {
  display: block;
}

body {
  background-color: $white;
  font-family: $default-font;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $default-font;
    font-size: $fs-text;
    color: $text;
  }
  p {
    font-style: normal;
    font-weight: 300;
    line-height: $fs-headline;
    font-family: $default-font;
    font-size: $fs-text;
    color: $text;
  }
  a,
  label {
    font-style: normal;
    line-height: $fs-headline;
    font-family: $default-font;
    font-size: $fs-text;
    color: $text;
  }
}

#root {
  position: relative;
  min-height: 100vh;

  .snackbar {
    &--error {
      background-color: $error;
    }

    &--success {
      background-color: #70ab4e;
    }

    .MuiPaper-root {
      flex-wrap: nowrap;
    }

    @media (max-width: 599.95px) {
      &-container {
        .MuiCollapse-container {
          @include rem-calc(padding-right, 20);
          @include rem-calc(padding-left, 20);
        }
      }
    }
  }
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

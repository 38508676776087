@import 'variables';
@import 'mixins';
%background {
  background: url(~assets/img/background-nise.png), #f1a6b7;
  background-blend-mode: screen, normal;
}

%content-wrapper {
  max-width: $tablet;
  padding: 0 $default-padding;
  width: 100%;
  box-sizing: border-box;
}

%headline {
  font-size: $fs-headline;
  @include rem-calc(line-height, 38);
  font-weight: bold;
  color: $headline;
}

%error-message {
  background: $error;
  color: $white;
  @include rem-calc(font-size, 15);
  @include rem-calc(line-height, 27);
  @include rem-calc(padding, 6, 12);
  @include rem-calc(border-radius, 5);
}

%section-logo {
  position: relative;
  @include rem-calc(width, 184);
  @include rem-calc(height, 184);
  background: url(~assets/img/background-nise.png),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 100%), #ed9eb0;
  background-blend-mode: screen, normal, normal;
  box-shadow: 0px 24px 46px #db899b, inset 0px 0px 22px #e399aa;
  border-radius: 35px;

  img {
    @include rem-calc(padding, 23);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

@mixin button($type: 'primary', $color: 'black') {
  display: block;
  box-sizing: border-box;

  text-transform: uppercase;
  @include rem-calc(height, 68);
  width: 100%;
  @include rem-calc(max-width, 335);
  padding: 0;
  cursor: pointer;

  @if $type == 'primary' {
    position: relative;
    color: $white;
    background: linear-gradient(transparent, $main-dark);
    border: none;
    @include rem-calc(border-radius, 5);
    outline: none;

    & > span {
      @include rem-calc(font-size, 15);
      @include rem-calc(line-height, 64);
      display: inline-block;
      @include rem-calc(margin-top, -4);
      transition: margin-top ease-in 0.125s;

      height: calc(100% - 4px);
      width: 100%;
      @include rem-calc(padding, 0, 20);
      background-color: $secondary;
      @include rem-calc(border-radius, 5);
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      // @include rem-calc(margin-bottom, -4);
      border-radius: inherit;
      // background-color: $main-dark;
      // transition: margin-bottom ease-in 0.125s;
    }

    &:hover {
      background: linear-gradient(transparent, lighten($main-dark, 5%));

      & > span {
        background-color: lighten($secondary, 5%);
      }
    }

    &:active,
    &--animate {
      & > span {
        @include rem-calc(margin-top, 4);
        background-color: lighten($secondary, 5%);
      }
    }

    &:disabled {
      cursor: unset;
      background: linear-gradient(transparent, lighten($main-dark, 30%));

      & > span {
        background-color: lighten($secondary, 40%);
      }
    }
  }

  @if $type == 'secondary' {
    @include rem-calc(height, 44);
    @include rem-calc(max-width, 335);
    position: relative;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    outline: none;
    background-color: transparent;
    transition: color ease-in 0.125s;
    @include rem-calc(padding, 0, 20);
    @if $color == 'white' {
      color: $white;
    }
    @if $color == 'black' {
      color: $black;
    }

    & > span {
      @include rem-calc(font-size, 15);
      @include rem-calc(line-height, 44);
      height: 100%;
      width: 100%;

      &::before,
      &::after {
        content: '';
        width: 2px;
        height: 0;
        position: absolute;
        transition: height 0.2s linear 0s, background-color 0.125s ease-in;
        @if $color == 'white' {
          background-color: $white;
        }
        @if $color == 'black' {
          background-color: $black;
        }
      }

      &::before {
        left: 0;
        top: 0;
      }

      &::after {
        right: 0;
        bottom: 0;
      }

      & > span {
        font-weight: 300;
        @include rem-calc(font-size, 29);
        @include rem-calc(line-height, 20);
        @include rem-calc(top, 3);
        position: relative;
      }
    }

    &::before,
    &::after {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      transition: width 0.2s linear 0.2s, background-color 0.125s ease-in;
      @if $color == 'white' {
        background-color: $white;
      }
      @if $color == 'black' {
        background-color: $black;
      }
    }
    &::before {
      right: 0;
      top: 0;
    }
    &::after {
      left: 0;
      bottom: 0;
    }

    &:hover {
      &::before,
      &::after {
        width: 100%;
        transition: width 0.2s linear 0s, background-color 0.125s ease-in;
      }
      span {
        &::before,
        &::after {
          height: 100%;
          transition: height 0.2s linear 0.2s, background-color 0.125s ease-in;
        }
      }
    }

    &:active {
      @if $color == 'white' {
        color: darken($white, 10%);
      }
      @if $color == 'black' {
        color: lighten($black, 40%);
      }
      &::before,
      &::after {
        @if $color == 'white' {
          background-color: darken($white, 10%);
        }
        @if $color == 'black' {
          background-color: lighten($black, 40%);
        }
      }

      span {
        &::before,
        &::after {
          @if $color == 'white' {
            background-color: darken($white, 10%);
          }
          @if $color == 'black' {
            background-color: lighten($black, 40%);
          }
        }
      }
    }
  }
}

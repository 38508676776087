// COLORS:
$white: #ffffff;
$black: #000000;

$text: #000000;
$text-secondary: #9b9b9b;
$headline: #000000;
$link: #000000;
$link-hovered: #4a4a4a;
$main: #f1a6b7;
$main-dark: #d36391;
$secondary: #2035b1;
$error: #da4e4e;

$lightgrey: #dddddd;
$semigrey: #cccccc;

// TYPOGRAPHY:
$fs-text: 1.1875rem; // 19px
$fs-headline: 1.75rem; //28px

// FONT FAMILY:
$default-font: 'GT Pressura';

// BREAKPOINTS
$mobileMiddle: 375px;
$mobileLarge: 425px;
$tablet: 768px;
$laptop: 64rem; // 1024px
$laptopMiddle: 1300px;
$laptopLarge: 1440px;

$tabletHeight: 576px;
$laptopHeight: 768px;
$laptopMiddleHeight: 960px;

// SIZES
$header-height: 5.8125rem; // 93px
$footer-height: 22.81rem; // 365px

$default-padding: 1.25rem; // 20px
